import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import  {Colors} from './../../utils/variables';
import ScrollFadeIn from './../../components/scrollFadeIn';
import ExpandableImageCard from './../../components/expandableImageCard';
import ThreeColumnBox from './../../components/threeColumnBox';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    const threeColumnContent = [
      'We recognize that the freedom to roam in the mountains, with people we love, is never to be taken for granted because our time here is never given.',
      'To ground ourselves as a business, we are planting our roots where we want to grow. The soil has been cultivated by our own hardships and nourished by the dear friends and family who took our tear stained faces in their hands and whispered "keep going."',
      'Peak Purpose is our response: let us help you, as you have helped us.'
    ];

    return (
		<div className="light-grey-background" style={{backgroundColor:Colors.primaryDark, paddingBottom:'10vh'}}>
      <div className="slant-bottom">
        <Hero
          title={'Peak Purpose'}
          subtitle={'Ride with a PURPOSE'}
          backgroundImage={'/images/peak-purpose-back.jpg'}
          height={'full'}
          tint={'#000000'}
        />
      </div>
      <div>
        <div className="gutter">
          <div className="medium-max-width-container story-lip lip shadow" style={{color:Colors.white, backgroundColor:Colors.primary, marginBottom:'2vh'}}>
            <div className="large-padding centered-text" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
              <h2>{'Welcome to Peak Purpose, the heart of Peak Pursuits.'}</h2>
              <p className="subheading">{'This represents what matters most to us and outlines what we are working towards.'}</p>
            </div>
          </div>
        </div>

        <div className="medium-max-width-container">
          <ScrollFadeIn>
            <ThreeColumnBox content={threeColumnContent} />
          </ScrollFadeIn>

          <ScrollFadeIn>
            <div className="medium-max-width-container" style={{color:Colors.white, marginTop: '20px'}}>
              <div className="large-padding centered-text">
                <h2>{'Because what if the greatest thing we ever do with our time, is something we do for someone else?'}</h2>
              </div>
            </div>
          </ScrollFadeIn>

          <ScrollFadeIn>
            <ExpandableImageCard
              title="Community"
              description="Knowing how hard some of life’s uphill climbs can be, our scholarship, Peak Purpose, was created to give an individual who has overcome injury or hardship the opportunity to attend Free to Roam."
              imageUrl="/images/peak-purpose-1.jpg"
              mobileImage="/images/peak-purpose-1-mobile.jpg"
              backgroundImage="/images/topo-texture.png"
              expandedContent={
                <div>
                  <p>{`By removing the financial barrier to attend our event, we hope you will feel supported, seen, and reminded that life’s climbs don’t have to be done alone. Community held us personally through our own heavy times and Peak Pursuits wants to extend the same to you.`}</p>
                  <p>{`We are excited to offer our first ever Peak Purpose Scholarship for Free to Roam 2025.  Please apply by July 1st, 2025 and applicants will be notified by email once a decision has been made.`}</p>
                  <p><b>{`To apply:`}</b></p>
                  <p>{`Tell us your story- however you’d like! We want to hear from you. Use whatever medium feels most comfortable to you: writing, video, a song! All submissions can be emailed to: `}<b><a href="mailto:peakpursuitsmtnbiking@gmail.com" style={{color:Colors.white}}>{'peakpursuitsmtnbiking@gmail.com'}</a></b></p>
                </div>
              }
            />
          </ScrollFadeIn>

          <ScrollFadeIn>
            <ExpandableImageCard
              title="Wild Spaces"
              description="Through our trail advocacy efforts, we are committed to supporting both traditional and adaptive trails that cater to riders of all abilities. By partnering with our local trail associations, trail builders, and High Fives Foundation, our goal is to advocate for the inclusion of adaptive trails in our local community as well as support the efforts of our trail associations."
              imageUrl="/images/peak-purpose-2.jpg"
              mobileImage="/images/peak-purpose-2-mobile.jpg"
              backgroundImage="/images/topo-texture.png"
              expandedContent={
                <div>
                  <p>{`Good biking comes from good trails and we are grateful for the trail builders and trail associations that advocate for our access to wild spaces on two-wheels. Trails are the lifeline of the mountain biking community, and as riders, we all share in the responsibility to ensure they remain accessible, sustainable, and welcoming for all riders—no matter their background or abilities.`}</p>
                  <p>{`We believe that true freedom in the mountains comes not just from riding through them, but from actively working to preserve, expand, and improve the trails that make mountain biking possible.`}</p>
                </div>
              }
            />
          </ScrollFadeIn>

          <ScrollFadeIn>
            <ExpandableImageCard
              title="Overcome"
              description="Have you ever had an idea so big, you were afraid to say it out loud? Well, this is ours. The idea of a TBI/ Concussion Clinic began after a personal experience with a brain injury. The trauma, physical and emotional, was heavy to carry."
              imageUrl="/images/peak-purpose-3.jpg"
              mobileImage="/images/peak-purpose-3-mobile.jpg"
              backgroundImage="/images/topo-texture.png"
              expandedContent={
                <div>
                  <p>{`We wondered, what if Peak Pursuits could be a catalyst to one day help fund/ collaborate to create a brain injury specific clinic in our hometown of South Lake Tahoe, CA?`}</p>
                  <p>{`The TBI clinic would support patients for six months after their injury and offer a wide range of services to treat the symptoms that result from these type of injuries. Primarily focused on concussions, TBIs, and sports-related concussions, the clinic would encompass physical and emotional recovery with ongoing support from trained medical professionals in Neuropsychology, Neurology, Physical Therapy, Speech Pathology, and Neurosurgery.`}</p>
                </div>
              }
            />
          </ScrollFadeIn>
        </div>
      </div>
      <ScrollFadeIn>
        <div className="medium-max-width-container shadow" style={{color:Colors.white, backgroundColor:Colors.primary, marginTop:'5vh'}}>
          <div className="large-padding centered-text" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
            <h1>{'Together, we can make a difference in our community and beyond.'}</h1>
            <p>{'Email: '}<b><a target="_blank" href="mailto:peakpursuitsmtnbiking@gmail.com" style={{color:Colors.white}}>{'peakpursuitsmtnbiking@gmail.com'}</a></b></p>
            <p>{'Instagram: '}<b><a target="_blank" href="https://www.instagram.com/peakpursuitsmtb/" style={{color:Colors.white}}>{'peakpursuitsmtb'}</a></b></p>
          </div>
        </div>
      </ScrollFadeIn>
    </div>
    );
  }
} 