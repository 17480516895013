import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import  {Colors} from './../../utils/variables';
import ScrollFadeIn from './../../components/scrollFadeIn';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div className="light-grey-background" style={{backgroundColor:Colors.primaryDark, paddingBottom:'10vh'}}>
      <div className="slant-bottom">
        <Hero
          title={'Referral Program'}
          subtitle={'Share the Adventure, Earn Rewards'}
          backgroundImage={'/images/referral-program-back.jpg'}
          height={'full'}
          tint={''}
        />
      </div>
      <div>
        <div className="gutter">
          <div className="medium-max-width-container story-lip lip shadow" style={{color:Colors.white, backgroundColor:Colors.primary}}>
            <div className="large-padding centered-text" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
            <h1>{'Refer THREE and Get Skillz for FREE '}</h1>
              <p className="subheading">{'We want to make it easier for you and your friends to come to Free to Roam. It’s our way of saying THANK YOU for being part of our mountain biking community and for your sincere support.'}</p>
            </div>
          </div>
          <div className="medium-max-width-container shadow" style={{marginTop:'5vh', marginBottom:'5vh', color:Colors.white, backgroundColor:Colors.primary}}>
            <ScrollFadeIn>
              <div className="row alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
                <div className="small-12 medium-6">
                  <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/referral-program-1.jpg)'}} />
                </div>
                <div className="small-12 medium-6 row">
                  <div className="alternating-grid-text center-position">
                    <h2>{"The Deal"}</h2>
                    <p><span className="subheading">{'We keep track of all the registered referrals you give us , so if you refer THREE friends ( and they sign up), you get to attend a skills clinic of your choice for FREE!'}</span></p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row alternating-grid-row reverse" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
                <div className="small-12 medium-6">
                  <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/referral-program-2.jpg)'}} />
                </div>
                <div className="small-12 medium-6 row">
                  <div className="alternating-grid-text center-position" style={{color:Colors.white}}>
                    <h2>{"How it works"}</h2>
                      <p style={{marginBottom:'1rem'}}><span className="subheading">{'Refer 3 people to sign up for the Peak Pursuits Women’s Mountain Bike Weekend, and you’ll get a FREE bike clinic of your choice during the event! '}</span></p>
                      <p style={{marginBottom:'1rem'}}><b><span style={{marginBottom:'1rem'}}>{'Bada- bing! It’s that simple!'}</span></b></p>  
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
                <div className="small-12 medium-6">
                  <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/referral-program-3.jpg)'}} />
                </div>
                <div className="small-12 medium-6 row">
                  <div className="alternating-grid-text center-position" style={{color:Colors.white}}>
                    <h2>{"How to Redeem"}</h2>
                    <p><span className="subheading">{'Once your friend has registered, please have them simply email us at peakpursuitsmtnbiking@gmail.com with your name and we’ll make sure you get credit and a big THANK YOU!'}</span></p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
          </div>
        </div>
        <ScrollFadeIn>
          <div className="medium-max-width-container shadow" style={{color:Colors.white, backgroundColor:Colors.primary, marginTop:'5vh'}}>
            <div className="large-padding centered-text" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
              <h2>{'Help us grow our women’s MTB community and ride more with your friends!'}</h2>
              <p className="subheading">{'Thanks again for being such an important part of Peak Pursuits Mountain Biking.  We couldn’t do it without you.'}</p>
            </div>
          </div>
        </ScrollFadeIn>
      </div>
    </div>
    );
  }
} 