import React, { Component } from "react";
import { Link } from "react-router-dom";
import './footer.scss';

export default class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<div className="footer-inner medium-padding centered-text">
					<img alt="" className="quarter-width" src="images/logo.png" style={{'maxWidth':'200px'}} />
					<div className="powered-by-stamp"><img src="/images/powered-by-ibis.png" /></div>
					<p className="white subheading">
						<Link className="white" style={{textDecoration:'none'}} to="/">{'Home'}</Link>
						{' | '}
						<Link className="white" style={{textDecoration:'none'}} to="/event">{'Event'}</Link>
						{' | '}
						<Link className="white" style={{textDecoration:'none'}} to="/story">{'Story'}</Link>
						{' | '}
						<Link className="white" style={{textDecoration:'none'}} to="/peak-purpose">{'Peak Purpose'}</Link>
						{' | '}
						<Link className="white" style={{textDecoration:'none'}} to="/referral-program">{'Referral Program'}</Link>
						{' | '}
						<Link className="white" style={{textDecoration:'none'}} to="/sponsors">{'Sponsors'}</Link>
						{' | '}
						<Link className="white" style={{textDecoration:'none'}} to="/forms">{'Forms & Documents'}</Link>
						{' | '}
						<Link className="white" style={{textDecoration:'none'}} to="/register">
							{'Register'}
						</Link>
					</p>
					<p className="white subheading copyright-text">
						&copy; Peak Pursuits MTB All Rights Reserved. Website built by a couple of kooks in tahoe that work for <a style={{color:'#fff', fontWeight:'bold', textDecoration:'none'}} target="_blank" href="https://www.alpine.design">Alpine Design LLC</a>
					</p>
				</div>
			</div>
		)
	}
}
