import React, { Component } from "react";
import Collapsible from 'react-collapsible';
import { Colors } from '../../utils/variables';
import './expandableImageCard.scss';

export default class ExpandableImageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleAccordion = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  render() {
    const { title, description, imageUrl, mobileImage, expandedContent, backgroundImage } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="expandable-image-card" style={{backgroundColor: Colors.primary, backgroundImage: `url(${backgroundImage || '/images/topo-texture.png'})`}}>
        <div className="expandable-image-card-content">
          <div className="image-section" style={{backgroundImage: `url(${imageUrl})`}}>
            <img className="mobile-image" src={mobileImage || imageUrl} />
          </div>
          <div className="text-section">
            <h1>{title}</h1>
            <p className="description subheading">{description}</p>
            <Collapsible 
              trigger={
                <div className="accordion-trigger" style={{backgroundColor: Colors.primaryDark, display:"inline-flex", padding:20, borderRadius:4}}>
                  <span>{isOpen ? 'Show Less' : 'Learn More'}</span>
                  <span className={`arrow ${isOpen ? 'up' : 'down'}`}>▼</span>
                </div>
              }
              onOpen={this.toggleAccordion}
              onClose={this.toggleAccordion}
            >
              <div className="expanded-content subheading">
                {expandedContent}
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    );
  }
} 