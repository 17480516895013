import React, {Component} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import ReactGA from 'react-ga';
import Header from "./components/header";
import StickyCountdown from "./components/stickyCountdown";
import Seo from "./utils/seo";
import Footer from "./components/footer";
import Home from "./pages/home";
import Contact from "./pages/contact";
import ThankYou from "./pages/thankYou";
import Event from "./pages/event";
import Story from "./pages/story";
import Sponsors from "./pages/sponsors";
import Mission from "./pages/mission";
import Forms from "./pages/forms";
import Register from "./pages/register";
import PeakPurpose from "./pages/peak-purpose";
import ReferralProgram from "./pages/referral-program";
import {Colors} from "./utils/variables";

//ReactGA.initialize('');

export class Layout extends Component {
  constructor(props) {
      super(props);
      this.state = ({

			})
  }
  componentDidMount = () =>{
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    return(
      <div>
        { this.props.children }
        <Footer />
      </div>
    )
  }
}

export default class App extends Component {
  constructor() {
      super();
			this.state = {}
  }
  componentDidMount = () =>{

  }

  render() {
   return (
     <div>
        <Router>
          <div>
            <Header />
            <StickyCountdown
              title="Sep 19th-25th 2025"
              time="Sep 19, 2025 15:37:25"
              titleColor={Colors.white}
              textColor={Colors.white}
              backgroundColor={Colors.primary}
            />
            <Routes>
              <Route path="/" element={<Layout><Home /></Layout>} />
              <Route path="/contact" element={<Layout><Contact /></Layout>} />
              <Route path="/thankyou" element={<Layout><ThankYou /></Layout>} />
              <Route path="/event" element={<Layout><Event /></Layout>} />
              <Route path="/forms" element={<Layout><Forms /></Layout>} />
              <Route path="/story" element={<Layout><Story /></Layout>} />
              <Route path="/mission" element={<Layout><Mission /></Layout>} />
              <Route path="/sponsors" element={<Layout><Sponsors /></Layout>} />
              <Route path="/register" element={<Layout><Register /></Layout>} />
              <Route path="/peak-purpose" element={<Layout><PeakPurpose /></Layout>} />
              <Route path="/referral-program" element={<Layout><ReferralProgram /></Layout>} />
            </Routes>
          </div>
        </Router>
      </div>

	 );
  }
}
