import React from 'react';
import { Colors } from '../../utils/variables';
import './threeColumnBox.scss';

const ThreeColumnBox = ({ content }) => {
  return (
    <div className="three-column-box">
      {content.map((text, index) => (
        <div key={index} className="column-box" style={{color: Colors.white, backgroundColor: Colors.primary}}>
          <div className="column-content" style={{backgroundImage: 'url(/images/topo-texture.png)'}}>
            <p className="subheading">{text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThreeColumnBox; 